@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'helvetica';
  src: url('./Assets/Fonts/helvetica_neue.ttf') format('truetype');
}

@font-face {
  font-family: 'helvetica-medium';
  src: url('./Assets/Fonts/helvetica_neue_63_medium_extended_oblique.ttf') format('truetype');
}
@font-face {
  font-family: 'helvetica-bold';
  src: url('./Assets/Fonts/HELVETICA-NEUE-BOLD.TTF') format('truetype');
}

@font-face {
  font-family: 'helvetica-ultralight';
  src: url('./Assets/Fonts/helvetica_neue_ultralight.ttf') format('truetype');
}

@font-face {
  font-family: 'helvetica-light';
  src: url('./Assets/Fonts/helveticaneue-light.ttf') format('truetype');
}



body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img{
  width: auto;
  max-width: 100%;
}

.text-btn{
  @apply hover:text-primary-500 text-sm transition-all;
}
.text-gradient{
  background-image: url('./Assets/Images/bg.png');
  background-size: 100%;
  @apply bg-clip-text text-transparent inline-block;
}
.bg-gradient-coustom{
  @apply bg-gradient-to-r from-secondary-500 to-primary-400 relative before:absolute before:top-0 before:left-0 before:right-0 before:h-1/2
  before:bg-gradient-to-b before:to-transparent before:from-pink-400/50;
}

.bg-gradient-coustom.btn{
  @apply before:rounded-t-3xl hover:bg-primary-500;
}
.btn{
  @apply inline-block sm:px-7 px-3 py-1  sm:py-2 rounded-3xl font-semibold  transition-all;
}


.marketersSlider .slick-prev{
  border-top: solid 2px #000;
  border-left: solid 2px #000;
  @apply w-3 h-3 -rotate-45 border-gray-700 opacity-100 inline-block;
 
}
.marketersSlider .slick-next{
  border-top: solid 2px #000;
  border-right: solid 2px #000;
  @apply w-3 h-3 rotate-45 border-gray-700 opacity-100 inline-block;
}
.marketersSlider .slick-arrow::before{
  @apply hidden;
}
.marketersSlider .slick-prev, .marketersSlider .slick-next{
  top: 40%;
}
.list-items li{
  @apply relative text-lg pl-8 before:absolute before:w-5 before:h-5 before:top-1 before:left-0 before:bg-primary
  before:rounded-full after:absolute after:w-2.5 after:h-1.5 after:border-l-2 after:border-b-2 after:border-white
  after:left-1 after:top-2.5 after:-rotate-[45deg]
}
.deliver-outcomes-section{
  @apply relative after:absolute after:left-0 after:right-0 after:bg-black after:bottom-0 after:h-1/2 bg-black lg:bg-transparent;
}

.deliver-outcomes-section::before{
  clip-path: ellipse(57% 39% at 50% 97%);
  content: "";
  @apply bg-black absolute  bottom-1/2 left-0 right-0 h-full lg:block hidden;
}

.portfolioSlider .slick-list{
  @apply pb-10;
}

.portfolioSlider .slick-prev, .portfolioSlider .slick-next{
  border:  solid 1px #ccc !important;
  @apply border rounded-md border-gray-700 w-9 h-9 inline-block -top-16 right-4 hover:bg-gray-300 transition-all
}
.portfolioSlider .slick-prev{
  @apply left-auto right-16
}

.portfolioSlider .slick-prev::before{
  border-top: solid 2px #000;
  border-left: solid 2px #000;
  @apply w-2 h-2 mt-3 ml-1 text-inherit -rotate-45 border-gray-700 opacity-100 inline-block;
}
.portfolioSlider .slick-next::before{
  border-top: solid 2px #000;
  border-right: solid 2px #000;
  @apply w-2 h-2 text-inherit mt-3 ml-0 rotate-45 border-gray-700 opacity-100 inline-block;
}
.form-control{
  @apply w-full bg-white px-4 py-2 outline-none rounded-sm h-12;
}

textarea.form-control{
  @apply h-auto resize-none;
}

.react-datepicker__navigation-icon{
  @apply top-1.5 !important;
}
.react-datepicker__navigation-icon::before{
  @apply border-gray-500 border-t-2 border-r-2 !important ;
}


